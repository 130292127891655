<script setup lang="ts">
const props = defineProps<{ v: any }>()
const { t } = useLocale()

const isCustomErrorIcon = computed(
  () => props.v.$error && props.v.$errors[0].$validator === 'customIcon'
)
const isCustomError = computed(() => props.v.$error && props.v.$errors[0].$validator === 'custom')

const message = computed(() => {
  if (isCustomError.value || isCustomErrorIcon.value) return props.v.$errors[0].$message

  const msg = t(`profile.VError.${props.v.$errors[0].$validator}`, props.v.$errors[0].$params)
  return msg
})
</script>

<template>
  <div
    v-if="v && v.$error"
    class="VError mt-1 text-xs text-mcd-red"
    :class="{ 'input-custom-error': isCustomErrorIcon }"
  >
    {{ message }}
  </div>
</template>

<style scoped>
.input-custom-error {
  @apply before:relative before:-bottom-[2.5px] before:mr-1 before:inline-block before:h-4 before:w-4 before:bg-cover before:bg-no-repeat before:content-[''] before:md:-bottom-1;
}

.input-custom-error::before {
  background: url('/images/icons/errorDLVmap.svg');
}
</style>
